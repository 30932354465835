import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h1>RECURSO NO ENCONTRADO</h1>
    <a href="/">Vuelve a la página principal</a>
  </Layout>
);

export default NotFoundPage;
